import './index.scss';

export default class Space {
  constructor({data}){
    this.wrapper = undefined;
    this.span = undefined;
    this.data = {
      value: data.value || 20
    };
    this.settings = [
      {
        name: 'plus',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"><path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"/></svg>`
      },
      {
        name: 'minus',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"><path d="M0 10h24v4h-24z"/></svg>`
      },
    ];
  }

  static get toolbox() {
    return {
      title: 'Space',
      icon: '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M13 6h4l-5-6-5 6h4v12h-4l5 6 5-6h-4z"/></svg>'
    };
  }

  renderSettings(){
    const wrapper = document.createElement('div');

    this.settings.forEach(tune => {
      let button = document.createElement('div');

      button.classList.add('cdx-settings-button');
      button.innerHTML = tune.icon;
      wrapper.appendChild(button);

      button.addEventListener('click', () => {
        if(tune.name === 'plus') {
          this.data.value += 5;
        }
        if(tune.name === 'minus') {
          if(this.data.value > 5) {
            this.data.value -= 5;
          }
        }
        this.changeHeight(this.data.value);
      });
    });

    return wrapper;
  }

  render(){
    this.wrapper = document.createElement('div');
    this.wrapper.classList.add('block--space');
    this.wrapper.style.height = this.data.value + 'px';

    this.span = document.createElement('span');
    this.span.classList.add('block--space-info');
    this.span.innerText=`${this.data.value}px`;
    this.wrapper.appendChild(this.span);

    return this.wrapper;
  }

  changeHeight(value) {
    this.span.innerText = `${value}px`;
    this.wrapper.style.height = `${value}px`;
  }

  save(blockContent){
    return Object.assign(this.data, {
      value: this.data.value || 20
    })
  }

  validate(savedData){
    if (savedData < 5){
      return false;
    }

    return true;
  }
}
