import client from './client';

/*
* 아티클 불러오기
* */
export const getArticle = async ({ token, articleId }) => {
    console.log(token, articleId)
    try {
        const response = await client.get(`/v1/article/regist/${articleId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        return response
    } catch(err) {
        console.log(err);
    }
};

/*
* 아티클 저장하기
* */
export const registerArticle = async ({ token, body }) => {
    return client.post(`/v1/article/regist`, body, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
};
