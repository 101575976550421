import React, { useCallback, useEffect, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import queryString from 'query-string';
import EditorJs from 'react-editor-js';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { EDITOR_JS_TOOLS } from './tools';
import { RITUAL_TOOLS } from './tools/ritualTool';
import client from './api/client';

import Header from './components/Header';

import { getArticle, registerArticle } from './api';
import ThumbnailModal from './components/ThumbnailModal';
import { byteLength } from './utils/byteLength';

import './styles/App.scss';
import 'react-datepicker/dist/react-datepicker.css';

/*
 * qs 에제
 * http://localhost:3000/about.html?idVersionContent=3&accessToken=eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJleHAiOjE2Mjc0NTc2NjMsImp0aSI6IjhlNTA4ZTMyLWEyNGQtNGMzNi04ZjUwLWVjNDQ2NmYyYjMxOCIsImNsaWVudF9pZCI6Imp3dGtleS1ncm9yby0xIiwidXNlcl9uYW1lIjoi7JeQ7J207JWE7Yq4IiwiYXV0aG9yaXRpZXMiOlsiUk9MRV9VU0VSIiwiUk9MRV9TVVBFUiIsIlJPTEVfQURNSU4iLCJST0xFX01BTkFHRVIiLCJST0xFX1BST0ZFU1NJT05BTF9QQVJUTkVSIl0sInNjb3BlIjpbInJlYWQiXX0.nvwxEL80bIEyyTyZpJsGRYuzSb_kLUQ_qZxgx9gsosbRqbnyGug8kzmxI9Hk2EWGASGylgv_LnwRRJQyNZ5lWaKxN3XqbaM1_Ozl7fPSiTDlEyvifkl6AEUjKYlJI8WcZGxnm7drJGnB5NwqjhKtPnP5Oe9S5EAACWUYgt0XLoHuy50IZEIqMNh1Y5mLvz-tc-S2C_YBZo2ANh_cQNQ8jUhmGhEV4-NOioiWtTgWHqDCNvOh0tNNkkWNguKtsX8a6_KH-36aRvhCtrG7GI9HnYpuuuUPmEsBMgrpOB-a2oqdOziohzAToqswRRbRgO07UEWxpXdO1LIkKAv9TWjApw&writer=슈퍼관리자&idArticle=
 * */
function App() {
  const editorRef = React.useRef(null);

  const [data, setData] = useState(null);
  const [title, setTitle] = useState('');
  const [subTitle, setSubTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [thumbnailPath, setThumbnailPath] = useState('');
  const [mobileThumbnailPath, setMobileThumbnailPath] = useState('');
  const [thumbCaption, setThumbCaption] = useState('');
  const [publishDate, setPublishDate] = useState(new Date());
  const [reservationType, setReservationType] = useState('temporary');
  const [reservationDate, setReservationDate] = useState(new Date());
  const [tts, setTts] = useState('');

  const [token, setToken] = useState('');
  const [idVersionContent, setIdVersionContent] = useState('');
  const [articleId, setArticleId] = useState('');

  // editor or tts
  const [showPcThumbnail, setShowPcThumbnail] = useState(true);
  const [tab, setTab] = useState('editor');

  const qs = queryString.parse(window.location.search);
  const isRitual = qs?.type === 'ritual';

  useEffect(() => {
    if (!isRitual) {
      if (!qs?.idVersionContent) {
        // VersionContent 컨테이너가 없을경우,
        alert('버전 컨테이너가 없습니다. 관리자에게 문의하세요.');
        return;
      }
      if (!qs?.accessToken) {
        // 토큰이 없을경우,
        alert('토큰이 없습니다. 관리자에게 문의하세요.');
        return;
      }
      setAuthor(qs?.writer);
      setIdVersionContent(qs?.idVersionContent);
      setToken(qs?.accessToken);
      setArticleId(qs?.idArticle);
    } else {
      // 리추얼이면
      client
        .get(`/v1/ritual/content/${qs?.idRitual}`)
        .then(({ data }) => {
          console.log(data?.data?.editorJsVersion);
          console.log(data?.data?.body);
          if (data?.data?.editorJsVersion) {
            // 에디터js
            setData(JSON.parse(data?.data?.body));
          }
        })
        .catch(e => {
          console.log(e);
        });
    }
  }, []);

  useEffect(() => {
    if (!isRitual && articleId) {
      getArticle({ token: qs?.accessToken, articleId: qs?.idArticle })
        .then(res => {
          const { data } = res?.data;
          console.log(JSON.parse(data?.body));
          setSubTitle(data?.subtitle);
          setTitle(data?.title);
          setThumbnailPath(data?.thumbnail?.url);
          setMobileThumbnailPath(data?.mobileThumbnail?.url);
          setThumbCaption(data?.thumbnail?.caption);
          setPublishDate(new Date(data?.releasedDate));
          setData(JSON.parse(data?.body));
          setTts(data?.ttsBody);
        })
        .catch(e => {
          console.log(e);
        });
    }
  }, [articleId]);

  const handleChange = useCallback((api, newData) => {
    setData(newData);
  }, []);

  // 아티클 서브밋
  const handleSubmit = useCallback(() => {
    if (!title) {
      alert('제목을 입력해주세요;');
      return false;
    }
    if (!thumbnailPath) {
      alert('썸네일을 등록해주세요;');
      return false;
    }

    let releaseVersion = false;
    let reservationTime = dayjs(reservationDate).format(
      `YYYY-MM-DDTHH:mm:ss.SSS`,
    );
    if (reservationType === 'immediately') {
      releaseVersion = true;
      reservationTime = dayjs(new Date()).format(`YYYY-MM-DDTHH:mm:ss.SSS`);
    } else if (reservationType === 'reservation') {
      releaseVersion = true;
    }

    registerArticle({
      token,
      body: {
        idVersionContent,
        title,
        subtitle: subTitle,
        body: JSON.stringify(data),
        thumbnail: {
          url: thumbnailPath,
          caption: thumbCaption,
        },
        mobileThumbnail: {
          url: mobileThumbnailPath,
          caption: thumbCaption,
        },
        releaseVersion,
        reservationTime,
        editorJsVersion: data?.version,
        releasedDate: dayjs(publishDate).format(`YYYY-MM-DDTHH:mm:ss.SSS`),
        ttsBody: tts,
      },
    })
      .then(res => {
        const { data } = res;
        if (data?.status?.code === 200) {
          alert('저장 완료!');
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, [
    title,
    author,
    thumbnailPath,
    mobileThumbnailPath,
    publishDate,
    data,
    token,
    idVersionContent,
    articleId,
    thumbCaption,
    reservationType,
    reservationDate,
    tts,
  ]);

  const handleSubmitRitualBody = useCallback(() => {
    client
      .post(
        `/v1/ritual/content/${qs?.idRitual}`,
        {
          body: JSON.stringify(data),
          editorJsVersion: data?.version,
        },
        {
          headers: {
            Authorization: `Bearer ${qs?.accessToken}`,
          },
        },
      )
      .then(res => {
        if (res?.data?.status?.code !== 200) {
          alert(res?.data?.status?.message);
          return;
        } else {
          alert('저장 되었습니다!');
          return;
        }
      });
  }, [data, token]);

  return (
    <>
      <Header
        isRitual={isRitual}
        onSubmit={isRitual ? handleSubmitRitualBody : handleSubmit}
        setReservationType={setReservationType}
        reservationType={reservationType}
        reservationDate={reservationDate}
        setReservationDate={setReservationDate}
        tab={tab}
        setTab={setTab}
      />
      {tab === 'editor' && (
        <Container>
          {!isRitual && (
            <HeaderContent>
              <TitleInput
                value={title}
                onChange={({ target }) => setTitle(target.value)}
                placeholder={'제목을 입력해주세요.'}
              />
              <SubTitleInput
                type='text'
                value={subTitle}
                onChange={({ target }) => setSubTitle(target.value)}
                placeholder={'부제목을 입력해주세요.'}
              />
              <p
                className={'subtitle-limit'}
                style={{ color: byteLength(subTitle) > 70 ? '#f00' : '#999' }}>
                {byteLength(subTitle)} / 70
              </p>
              <div className={'more-info'}>
                <DateBox>
                  <DatePicker
                    showTimeSelect
                    selected={publishDate}
                    // closeOnScroll={true}
                    onChange={date => setPublishDate(date)}
                    // dateFormat={'yyyy-MM-dd HH:mm'}
                    dateFormat={'yyyy.M.dd.'}
                  />
                  <p>{author || ''}</p>
                </DateBox>
              </div>

              <div className={'thumbbox'}>
                <ThumbnailModal
                  setThumbnailPath={setThumbnailPath}
                  setMobileThumbnailPath={setMobileThumbnailPath}
                />
                {
                  // pc 혹은 mobile 썸네일이 있으면 해당 이미지 노출
                  thumbnailPath || mobileThumbnailPath ? (
                    <div style={{ minHeight: 60, background: '#eee' }}>
                      <img
                        src={
                          showPcThumbnail ? thumbnailPath : mobileThumbnailPath
                        }
                        alt=''
                      />
                    </div>
                  ) : (
                    <label className={'thumbnail-label'}>
                      <p>썸네일을 등록해주세요.</p>
                    </label>
                  )
                }
                <button
                  className={'changeButton'}
                  onClick={() => setShowPcThumbnail(!showPcThumbnail)}>
                  <p>
                    {showPcThumbnail ? 'MOBILE' : 'PC'}
                    <br />
                    보기
                  </p>
                </button>
                <input
                  type='text'
                  className={'thumbCaption'}
                  placeholder={'Caption'}
                  onChange={e => setThumbCaption(e.target.value)}
                  value={thumbCaption}
                />
              </div>
            </HeaderContent>
          )}

          <EditorJs
            ref={editorRef}
            holder={'editor-layout'}
            onChange={handleChange}
            tools={isRitual ? RITUAL_TOOLS : EDITOR_JS_TOOLS}
            data={data}
            onReady={e => {
              if (data?.time) {
                e.render(data);
              }
            }}>
            <div id='editor-layout' className={'editor-layout'} />
          </EditorJs>
        </Container>
      )}
      {tab === 'tts' && (
        <TtsContainer>
          <TtsTextArea
            placeholder={'내용을 입력해주세요.'}
            value={tts}
            onChange={({ target }) => setTts(target.value)}
          />
        </TtsContainer>
      )}
    </>
  );
}

const Container = styled.div`
    width: 100%;
    max-width: 974.25px;
    background:#fff;
    margin:0 auto;
    padding: 5rem 30px;
    box-sizing: border-box;
    margin-top: 60px;
    @media screen and (max-width: 650px) {
        padding: 3rem 10px 60px;
    }
    @media screen and (max-width: 768px) {
        margin-top: 0;
    }
    
}
`;
const DateBox = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #b3b3b3;
  input {
    border: none;
    font-size: 12px;
    color: #b3b3b3;
    width: 100px;
  }
`;
const HeaderContent = styled.div`
  position: relative;
  z-index: 5000;
  padding: 0;
  margin-bottom: 40px;

  .subtitle-limit {
    text-align: right;
    font-size: 12px;
    color: #999;
  }
  .more-info {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-top: 53px;
    position: relative;
    z-index: 50;
    @media screen and (max-width: 650px) {
      margin-top: 50px;
    }
  }

  .status {
    display: flex;
    align-items: center;
    color: #0e0e0e;
    span {
      display: flex;
      align-items: center;
      margin-left: 15px;
      font-size: 12px;
      img {
        margin-right: 3px;
      }
    }
  }

  .thumbbox {
    width: 100%;
    position: relative;
    margin-top: 20px;

    img {
      width: 100%;
    }

    .changeButton {
      cursor: pointer;
      border: none;
      position: absolute;
      right: 0;
      top: 45px;
      width: 60px;
      height: 60px;
      background: #999;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
    }

    .thumbnail-label {
      cursor: pointer;
      display: block;
      width: 100%;
      padding: 20% 0;
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 30px;
      background: #f0f0f0;
      color: #ddd;
    }

    .thumbCaption {
      border-radius: 3px;
      padding: 10px 12px;
      outline: none;
      width: 100%;
      border: none;
      text-align: center;
      box-shadow: none;
      padding-top: 10px;
      font-size: var(--chakra-fontSizes-xs);
      color: var(--chakra-colors-gray-line);
      line-height: 1;
      padding-bottom: 10px;
      &::placeholder {
        color: #b3b3b3;
      }
    }
  }
`;

const TtsContainer = styled.div`
  width: 100%;
  max-width: 974.25px;
  background: #fff;
  margin: 0 auto;
  padding: 2rem 30px 5rem;
  box-sizing: border-box;
  margin-top: 60px;
  @media screen and (max-width: 650px) {
    padding: 3rem 10px 60px;
  }
  @media screen and (max-width: 768px) {
    margin-top: 0;
  }
`;
const TtsTextArea = styled(TextareaAutosize)`
  font-family: 'Noto Sans KR', sans-serif !important;
  width: 100%;
  font-size: 16px;
  line-height: 1.5rem;
  border: none;
  padding: 1em;
  padding-bottom: 5em;
`;

const TitleInput = styled(TextareaAutosize)`
  font-family: 'Noto Sans KR', sans-serif !important;
  border: none;
  text-align: left;
  width: 100%;
  min-width: 100%;
  font-size: 52px;
  line-height: 78px;
  font-weight: 700;
  resize: none;
  letter-spacing: -0.45px;
  &::placeholder {
    color: #ddd;
  }

  @media screen and (max-width: 650px) {
    font-size: 32px;
    line-height: 32px;
  }
`;
const SubTitleInput = styled(TextareaAutosize)`
  font-family: 'Noto Sans KR', sans-serif !important;
  border: none;
  width: 100%;
  font-size: 28px;
  line-height: 40px;
  margin-top: 30px;
  resize: none;
  &::placeholder {
    color: #ddd;
  }
  @media screen and (max-width: 650px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export default App;
