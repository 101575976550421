import Header from '@editorjs/header'

export default class CustomHeader2 extends Header {
  constructor({ data, config, api, readOnly }) {
    super({ data, config, api, readOnly })

    this._CSS.wrapper = 'heading-text'
    this._element = this.getTag();
  }

  static get toolbox() {
    return {
      title: '중제목',
      icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
          '<path d="M4 4V11H11V4H13V20H11V13H4V20H2V4H4ZM18.5 8C20.571 8 22.25 9.679 22.25 11.75C22.25 12.607 21.962 13.398 21.478 14.03L21.33 14.21L18.034 18H22V20H15V18.444L19.82 12.898C20.088 12.591 20.25 12.189 20.25 11.75C20.25 10.784 19.466 10 18.5 10C17.582 10 16.829 10.707 16.756 11.606L16.75 11.75H14.75C14.75 9.679 16.429 8 18.5 8Z" fill="#03053D"/>\n' +
          '</svg>\n'
    };
  }

  onPaste(event) {
    const data = {
      text: event.detail.data.innerHTML.replace(/<[^>]*>/g, '')
    };

    this.data = data;
  }

  getTag() {
    const tag = super.getTag()
    let headerClass = 'grab_heading__2';
    tag.classList.add(headerClass, 'grab_module');

    return tag;
  }
}
