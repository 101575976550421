import Header from '@editorjs/header'

export default class CustomHeader1 extends Header {
  constructor({ data, config, api, readOnly }) {
    super({ data, config, api, readOnly })

    this._CSS.wrapper = 'heading-text'
    this._element = this.getTag();
  }

  static get toolbox() {
    return {
      title: '대제목',
      icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
          '<path d="M13 20H11V13H4V20H2V4H4V11H11V4H13V20ZM21 8V20H19V10.204L17 10.74V8.67L19.5 8H21Z" fill="#03053D"/>\n' +
          '</svg>\n'
    };
  }


  getTag() {
    const tag = super.getTag()
    let headerClass = 'grab_heading__1';
    tag.classList.add(headerClass, 'grab_module');

    return tag;
  }
}
