import Paragraph from './paragraph/paragraph';
import Image from './images';
import SimpleImage from '@editorjs/simple-image';
import Underline from '@editorjs/underline';
import Strikethrough from 'editorjs-strikethrough';
import TextAlignment from 'editorjs-text-alignment-blocktune';
import FontSize from 'editorjs-inline-font-size-tool';
import ColorPlugin from 'editorjs-text-color-plugin';
import Embed from '@editorjs/embed';

import client, { FILE_SERVER_URL } from '../api/client';

import Space from './space';
import cutomHeader1 from './customHeader/h1';
import cutomHeader2 from './customHeader/h2';
import cutomHeader3 from './customHeader/h3';

import CustomParagraph1 from './paragraph/p1';
import CustomParagraph2 from './paragraph/p2';
import CustomBlockQuote from './paragraph/blockquote';

import Slide from './slide';
import Divider from './divider';
import Divider2 from './divider2';
import { LayoutBlockTool } from 'editorjs-layout';
import CustomBlockQuoteLine from './paragraph/blockquoteLine';
import LineBox from './paragraph/linebox';
import YoutubeEmbed from './embedYoutube';
import NumberList from './list/NumberList';
import BulletList from './list/BulletList';
import LinkTool from './links';

export function fileUpload(file) {
  const formdata = new FormData();
  formdata.append('file', file);
  return client
    .post('/v1/files', formdata)
    .then(res => {
      const { data } = res;
      return {
        success: 1,
        file: {
          url: data?.data.url,
        },
      };
    })
    .catch(err => {
      console.log(err);
      alert('이미지 업로드 에러!');
    });
}

const ImageTool = {
  class: Image,
  config: {
    uploader: {
      uploadByFile: file => fileUpload(file),
    },
  },
  toolbox: {
    icon:
      '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<path d="M20 12V6H12H4V12H2V5.5C2 5.10218 2.15051 4.72064 2.41842 4.43934C2.68633 4.15804 3.04969 4 3.42857 4H20.5714C20.9503 4 21.3137 4.15804 21.5816 4.43934C21.8495 4.72064 22 5.10218 22 5.5V12H20ZM4 12V18H20V12H22V18.5C22 18.8978 21.8495 19.2794 21.5816 19.5607C21.3137 19.842 20.9503 20 20.5714 20H3.42857C3.04969 20 2.68633 19.842 2.41842 19.5607C2.15051 19.2794 2 18.8978 2 18.5V12H4Z" fill="#03053D"/>\n' +
      '<path d="M2.4 11.2C1.95817 11.5314 1.86863 12.1582 2.2 12.6C2.53137 13.0418 3.15817 13.1314 3.6 12.8L2.4 11.2ZM7 9L7.64594 8.23661L7.03756 7.72183L6.4 8.2L7 9ZM13.5 14.5L12.8541 15.2634L13.4481 15.766L14.0812 15.3137L13.5 14.5ZM17 12L17.53 11.152L16.9629 10.7976L16.4188 11.1863L17 12ZM3.6 12.8L7.6 9.8L6.4 8.2L2.4 11.2L3.6 12.8ZM6.35406 9.76339L12.8541 15.2634L14.1459 13.7366L7.64594 8.23661L6.35406 9.76339ZM14.0812 15.3137L17.5812 12.8137L16.4188 11.1863L12.9188 13.6863L14.0812 15.3137ZM16.47 12.848L20.47 15.348L21.53 13.652L17.53 11.152L16.47 12.848Z" fill="#03053D"/>\n' +
      '</svg>',
    title: '이미지',
  },
};

/*
 * 컬럼 내부 에디터 툴
 * */
const COLUMN_DEITOR_JS_TOOLS = {
  // inline
  textAlignMent: {
    class: TextAlignment,
  },
  paragraph: {
    class: Paragraph,
    tunes: ['textAlignMent'],
    inlineToolbar: true,
  },
  header1: {
    class: cutomHeader1,
    tunes: ['textAlignMent'],
    inlineToolbar: true,
    config: {
      levels: [1, 2, 3],
      defaultLevel: 1,
    },
  },
  header2: {
    class: cutomHeader2,
    tunes: ['textAlignMent'],
    inlineToolbar: true,
    config: {
      levels: [1, 2, 3],
      defaultLevel: 2,
    },
  },
  header3: {
    class: cutomHeader3,
    tunes: ['textAlignMent'],
    inlineToolbar: true,
    config: {
      levels: [1, 2, 3],
      defaultLevel: 3,
    },
  },
  image: ImageTool,
};

/*
 * 메인 데이터 툴
 * */
export const EDITOR_JS_TOOLS = {
  paragraph: {
    class: CustomParagraph1,
    tunes: ['textAlignMent'],
    inlineToolbar: true,
  },
  paragraph2: {
    class: CustomParagraph2,
    tunes: ['textAlignMent'],
    inlineToolbar: true,
  },

  // inline options
  // fontSize: FontSize,
  // fontFamily: FontFamily,
  Color: {
    class: ColorPlugin,
    config: {
      colorCollections: ['#0E0E0E', '#999999', '#009A9E', '#A39382', '#fff'],
      defaultColor: '#0E0E0E',
      type: 'text',
    },
  },
  Marker: {
    class: ColorPlugin,
    config: {
      defaultColor: '#FFBF00',
      type: 'marker',
    },
  },
  // underline: {
  //   class: Underline,
  //   toolbox: {
  //     icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
  //         '<path d="M15.246 14H8.754L7.154 18H5L11 3H13L19 18H16.846L15.246 14ZM14.446 12L12 5.885L9.554 12H14.446ZM3 20H21V22H3V20Z" fill="#03053D"/>\n' +
  //         '</svg>',
  //     title: '밑선'
  //   }
  // },
  // strikethrough :Strikethrough,
  textAlignMent: {
    class: TextAlignment,
    config: {},
  },

  header1: {
    class: cutomHeader1,
    tunes: ['textAlignMent'],
    inlineToolbar: true,
    config: {
      levels: [1, 2, 3],
      defaultLevel: 1,
    },
  },
  header2: {
    class: cutomHeader2,
    tunes: ['textAlignMent'],
    inlineToolbar: true,
    config: {
      levels: [1, 2, 3],
      defaultLevel: 2,
    },
  },
  header3: {
    class: cutomHeader3,
    tunes: ['textAlignMent'],
    inlineToolbar: true,
    config: {
      levels: [1, 2, 3],
      defaultLevel: 3,
    },
  },

  image: ImageTool,

  slide: {
    class: Slide,
    config: {
      uploader: {
        uploadByFile: file => fileUpload(file),
      },
    },
    toolbox: {
      icon:
        '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M17 12.5V9H12H7V12.5H5V8.03125C5 7.75775 5.10536 7.49544 5.29289 7.30205C5.48043 7.10865 5.73478 7 6 7H18C18.2652 7 18.5196 7.10865 18.7071 7.30205C18.8946 7.49544 19 7.75775 19 8.03125V12.5H17ZM7 12.5V16H17V12.5H19V16.9688C19 17.2423 18.8946 17.5046 18.7071 17.698C18.5196 17.8913 18.2652 18 18 18H6C5.73478 18 5.48043 17.8913 5.29289 17.698C5.10536 17.5046 5 17.2423 5 16.9688V12.5H7Z" fill="#03053D"/>\n' +
        '<path d="M5.4453 12.1679C4.98577 12.4743 4.8616 13.0952 5.16795 13.5547C5.4743 14.0142 6.09517 14.1384 6.5547 13.8321L5.4453 12.1679ZM9 11L9.6 10.2L9.03399 9.77549L8.4453 10.1679L9 11ZM13 14L12.4 14.8L12.9379 15.2034L13.5145 14.8575L13 14ZM15.5 12.5L16.0145 11.6425L15.5 11.3338L14.9855 11.6425L15.5 12.5ZM6.5547 13.8321L9.5547 11.8321L8.4453 10.1679L5.4453 12.1679L6.5547 13.8321ZM8.4 11.8L12.4 14.8L13.6 13.2L9.6 10.2L8.4 11.8ZM13.5145 14.8575L16.0145 13.3575L14.9855 11.6425L12.4855 13.1425L13.5145 14.8575ZM14.9855 13.3575L17.4855 14.8575L18.5145 13.1425L16.0145 11.6425L14.9855 13.3575Z" fill="#03053D"/>\n' +
        '<path d="M2.38498e-08 12L3 10.2679L3 13.7321L2.38498e-08 12Z" fill="#03053D"/>\n' +
        '<path d="M24 12L21 13.7321L21 10.2679L24 12Z" fill="#03053D"/>\n' +
        '</svg>\n',
      title: '갤러리이미지',
    },
  },
  layout: {
    class: LayoutBlockTool,
    config: {
      EditorJS: window.EditorJS,
      editorJSConfig: {
        tools: COLUMN_DEITOR_JS_TOOLS,
      },
      enableLayoutEditing: true,
      enableLayoutSaving: true,
      initialData: {
        itemContent: {
          1: {
            blocks: [],
          },
          2: {
            blocks: [],
          },
        },
        layout: {
          type: 'container',
          className: 'grab_column',
          children: [
            {
              type: 'item',
              className: 'grab_column__item',
              itemContentId: '1',
            },
            {
              type: 'item',
              className: 'grab_column__item',
              itemContentId: '2',
            },
          ],
        },
      },
    },
    toolbox: {
      icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.0588 3C19.5784 3 20 3.448 20 4V20C20 20.552 19.5784 21 19.0588 21H13.4118C12.8922 21 12.4706 20.552 12.4706 20V4C12.4706 3.448 12.8922 3 13.4118 3H19.0588Z" fill="#03053D"/>
<path d="M10.5882 3C11.1078 3 11.5294 3.448 11.5294 4V20C11.5294 20.552 11.1078 21 10.5882 21H4.94118C4.42165 21 4 20.552 4 20V4C4 3.448 4.42165 3 4.94118 3H10.5882Z" fill="#03053D"/>
</svg>`,
      title: '2단',
    },
  },
  bulletList: {
    class: BulletList,
    toolbox: {
      icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M8 4H21V6H8V4ZM4.5 6.5C4.10218 6.5 3.72064 6.34196 3.43934 6.06066C3.15804 5.77936 3 5.39782 3 5C3 4.60218 3.15804 4.22064 3.43934 3.93934C3.72064 3.65804 4.10218 3.5 4.5 3.5C4.89782 3.5 5.27936 3.65804 5.56066 3.93934C5.84196 4.22064 6 4.60218 6 5C6 5.39782 5.84196 5.77936 5.56066 6.06066C5.27936 6.34196 4.89782 6.5 4.5 6.5ZM4.5 13.5C4.10218 13.5 3.72064 13.342 3.43934 13.0607C3.15804 12.7794 3 12.3978 3 12C3 11.6022 3.15804 11.2206 3.43934 10.9393C3.72064 10.658 4.10218 10.5 4.5 10.5C4.89782 10.5 5.27936 10.658 5.56066 10.9393C5.84196 11.2206 6 11.6022 6 12C6 12.3978 5.84196 12.7794 5.56066 13.0607C5.27936 13.342 4.89782 13.5 4.5 13.5ZM4.5 20.4C4.10218 20.4 3.72064 20.242 3.43934 19.9607C3.15804 19.6794 3 19.2978 3 18.9C3 18.5022 3.15804 18.1206 3.43934 17.8393C3.72064 17.558 4.10218 17.4 4.5 17.4C4.89782 17.4 5.27936 17.558 5.56066 17.8393C5.84196 18.1206 6 18.5022 6 18.9C6 19.2978 5.84196 19.6794 5.56066 19.9607C5.27936 20.242 4.89782 20.4 4.5 20.4ZM8 11H21V13H8V11ZM8 18H21V20H8V18Z" fill="#03053D"/>
</svg>`,
      title: '블릿 리스트',
    },
  },
  numberList: {
    class: NumberList,
    toolbox: {
      icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 4H21V6H8V4ZM5 3V6H6V7H3V6H4V4H3V3H5ZM3 14V11.5H5V11H3V10H6V12.5H4V13H6V14H3ZM5 19.5H3V18.5H5V18H3V17H6V21H3V20H5V19.5ZM8 11H21V13H8V11ZM8 18H21V20H8V18Z" fill="#03053D"/>
</svg>
`,
      title: '숫자 리스트',
    },
  },

  divider: {
    class: Divider,
    toolbox: {
      icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="2" y="11" width="20" height="2" fill="#03053D"/>
</svg>`,
      title: '긴 구분선',
    },
  },
  divider2: {
    class: Divider2,
    toolbox: {
      icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="7" y="11" width="10" height="2" fill="#03053D"/>
</svg>`,
      title: '짧은 구분선',
    },
  },
  linebox: {
    class: LineBox,
    inlineToolbar: true,
    toolbox: {
      icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M20 9V11V13V15H4V13V11L4 9L20 9ZM2 11V8C2 7.73478 2.10536 7.48043 2.29289 7.29289C2.48043 7.10536 2.73478 7 3 7H21C21.2652 7 21.5196 7.10536 21.7071 7.29289C21.8946 7.48043 22 7.73478 22 8V11V13V16C22 16.2652 21.8946 16.5196 21.7071 16.7071C21.5196 16.8946 21.2652 17 21 17H3C2.73478 17 2.48043 16.8946 2.29289 16.7071C2.10536 16.5196 2 16.2652 2 16V13V11Z" fill="#03053D"/>
</svg>`,
      title: '정보',
    },
  },
  quote: {
    class: CustomBlockQuote,
    inlineToolbar: true,
    toolbox: {
      icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.583 17.321C3.553 16.227 3 15 3 13.011C3 9.511 5.457 6.374 9.03 4.823L9.923 6.201C6.588 8.005 5.936 10.346 5.676 11.822C6.213 11.544 6.916 11.447 7.605 11.511C9.409 11.678 10.831 13.159 10.831 15C10.831 15.9283 10.4623 16.8185 9.80587 17.4749C9.1495 18.1313 8.25926 18.5 7.331 18.5C6.258 18.5 5.232 18.01 4.583 17.321ZM14.583 17.321C13.553 16.227 13 15 13 13.011C13 9.511 15.457 6.374 19.03 4.823L19.923 6.201C16.588 8.005 15.936 10.346 15.676 11.822C16.213 11.544 16.916 11.447 17.605 11.511C19.409 11.678 20.831 13.159 20.831 15C20.831 15.9283 20.4623 16.8185 19.8059 17.4749C19.1495 18.1313 18.2593 18.5 17.331 18.5C16.258 18.5 15.232 18.01 14.583 17.321Z" fill="#03053D"/>
</svg>`,
      title: '따옴표 인용',
    },
  },
  quoteLine: {
    class: CustomBlockQuoteLine,
    inlineToolbar: true,
    toolbox: {
      icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="6" y="4" width="2" height="16" fill="#03053D"/>
<rect x="10" y="12" width="8" height="2" fill="#03053D"/>
<rect x="13" y="20" width="7" height="2" transform="rotate(-90 13 20)" fill="#03053D"/>
</svg>`,
      title: '라인 인용글',
    },
  },
  embed: {
    class: YoutubeEmbed,
    toolbox: {
      icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20 12V6.75H12H4V12H2V6.3125C2 5.9644 2.15051 5.63056 2.41842 5.38442C2.68633 5.13828 3.04969 5 3.42857 5H20.5714C20.9503 5 21.3137 5.13828 21.5816 5.38442C21.8495 5.63056 22 5.9644 22 6.3125V12H20ZM4 12V17.25H20V12H22V17.6875C22 18.0356 21.8495 18.3694 21.5816 18.6156C21.3137 18.8617 20.9503 19 20.5714 19H3.42857C3.04969 19 2.68633 18.8617 2.41842 18.6156C2.15051 18.3694 2 18.0356 2 17.6875V12H4Z" fill="#03053D"/>
<path d="M15 12L10.5 14.5981L10.5 9.40192L15 12Z" fill="#03053D"/>
</svg>
`,
      title: '유튜브',
    },
  },
  linkTool: {
    class: LinkTool,
    config: {
      endpoint: 'https://api.groro.co.kr/api/v1/fetchUrl', // Your backend endpoint for url data fetching
    },
    toolbox: {
      icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.657 14.828L16.243 13.414L17.657 12C18.0284 11.6286 18.3231 11.1876 18.5241 10.7023C18.7251 10.217 18.8286 9.69681 18.8286 9.1715C18.8286 8.6462 18.7251 8.12605 18.5241 7.64073C18.3231 7.15542 18.0284 6.71445 17.657 6.343C17.2856 5.97156 16.8446 5.67691 16.3593 5.47589C15.874 5.27487 15.3538 5.1714 14.8285 5.1714C14.3032 5.1714 13.783 5.27487 13.2977 5.47589C12.8124 5.67691 12.3714 5.97156 12 6.343L10.586 7.757L9.172 6.343L10.586 4.929C11.7143 3.81892 13.2356 3.19966 14.8184 3.2061C16.4012 3.21255 17.9174 3.84418 19.0366 4.96341C20.1558 6.08265 20.7875 7.5988 20.7939 9.18162C20.8003 10.7644 20.1811 12.2857 19.071 13.414L17.657 14.828ZM14.828 17.657L13.414 19.071C12.8585 19.6356 12.1967 20.0846 11.4669 20.3922C10.737 20.6998 9.95341 20.8598 9.16138 20.863C8.36934 20.8662 7.58451 20.7126 6.85214 20.411C6.11977 20.1094 5.45437 19.6658 4.89431 19.1057C4.33425 18.5456 3.89062 17.8802 3.58901 17.1479C3.2874 16.4155 3.13377 15.6307 3.137 14.8386C3.14022 14.0466 3.30023 13.263 3.6078 12.5331C3.91536 11.8033 4.3644 11.1415 4.929 10.586L6.343 9.172L7.757 10.586L6.343 12C5.97155 12.3714 5.67691 12.8124 5.47589 13.2977C5.27486 13.783 5.1714 14.3032 5.1714 14.8285C5.1714 15.3538 5.27486 15.874 5.47589 16.3593C5.67691 16.8446 5.97155 17.2856 6.343 17.657C6.71444 18.0284 7.15541 18.3231 7.64072 18.5241C8.12604 18.7251 8.6462 18.8286 9.1715 18.8286C9.6968 18.8286 10.217 18.7251 10.7023 18.5241C11.1876 18.3231 11.6286 18.0284 12 17.657L13.414 16.243L14.828 17.657ZM14.828 7.757L16.243 9.172L9.172 16.242L7.757 14.828L14.828 7.758V7.757Z" fill="#03053D"/>
</svg>
`,
      title: '링크',
    },
  },
  space: {
    class: Space,
    toolbox: {
      icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17 21V17H7V21H5V16C5 15.7348 5.10536 15.4804 5.29289 15.2929C5.48043 15.1054 5.73478 15 6 15H18C18.2652 15 18.5196 15.1054 18.7071 15.2929C18.8946 15.4804 19 15.7348 19 16V21H17ZM7 3V7H17V3H19V8C19 8.26522 18.8946 8.51957 18.7071 8.70711C18.5196 8.89464 18.2652 9 18 9H6C5.73478 9 5.48043 8.89464 5.29289 8.70711C5.10536 8.51957 5 8.26522 5 8V3H7ZM2 9L6 12L2 15V9ZM22 9V15L18 12L22 9Z" fill="#03053D"/>
</svg>
`,
      title: '여백',
    },
  },
};
