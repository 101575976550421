export default class CustomParagraph2 {
    static get DEFAULT_PLACEHOLDER() {
        return '';
    }

    constructor({data, config, api, readOnly}) {
        this.api = api;
        this.readOnly = readOnly;

        this._CSS = {
            block: this.api.styles.block,
            wrapper: 'ce-paragraph'
        };

        if (!this.readOnly) {
            this.onKeyUp = this.onKeyUp.bind(this);
        }

        this._placeholder = config.placeholder ? config.placeholder : CustomParagraph2.DEFAULT_PLACEHOLDER;
        this._data = {};
        this._element = this.drawView();
        this._preserveBlank = config.preserveBlank !== undefined ? config.preserveBlank : false;

        this._icon = config.icon ? config.icon : ''

        this.data = data;
    }

    onKeyUp(e) {
        if (e.code !== 'Backspace' && e.code !== 'Delete') {
            return;
        }

        const {textContent} = this._element;

        if (textContent === '') {
            this._element.innerHTML = '';
        }
    }

    drawView() {
        let div = document.createElement('DIV');

        div.classList.add(this._CSS.wrapper, this._CSS.block, 'grab_module', 'grab_paragraph__sm');
        div.contentEditable = false;
        div.dataset.placeholder = this.api.i18n.t(this._placeholder);

        if (!this.readOnly) {
            div.contentEditable = true;
            div.addEventListener('keyup', this.onKeyUp);
        }

        return div;
    }

    render() {
        return this._element;
    }

    merge(data) {
        let newData = {
            text : this.data.text + data.text
        };

        this.data = newData;
    }

    validate(savedData) {
        if (savedData.text.trim() === '' && !this._preserveBlank) {
            return false;
        }

        return true;
    }

    save(toolsContent) {
        return {
            text: toolsContent.innerHTML,
        };
    }

    onPaste(event) {
        const data = {
            text: event.detail.data.innerHTML.innerHTML.replace(/<[^>]*>/g, '')
        };

        this.data = data;
    }

    static get conversionConfig() {
        return {
            export: 'text', // to convert Paragraph to other block, use 'text' property of saved data
            import: 'text' // to covert other block's exported string to Paragraph, fill 'text' property of tool data
        };
    }

    static get sanitize() {
        return {
            text: {
                br: true,
            }
        };
    }

    static get isReadOnlySupported() {
        return true;
    }

    get data() {
        let text = this._element.innerHTML;

        this._data.text = text;

        return this._data;
    }

    set data(data) {
        this._data = data || {};

        this._element.innerHTML = this._data.text || '';
    }

    static get pasteConfig() {
        return {
            tags: [ 'P' ]
        };
    }

    static get toolbox() {
        return {
            title: '본문(작게)',
            icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                '<path d="M18.5 8C20.571 8 22.25 9.679 22.25 11.75C22.25 12.607 21.962 13.398 21.478 14.03L21.33 14.21L18.034 18H22V20H15V18.444L19.82 12.898C20.088 12.591 20.25 12.189 20.25 11.75C20.25 10.784 19.466 10 18.5 10C17.582 10 16.829 10.707 16.756 11.606L16.75 11.75H14.75C14.75 9.679 16.429 8 18.5 8Z" fill="#03053D"/>\n' +
                '<rect x="2" y="4" width="12" height="2" fill="#03053D"/>\n' +
                '<rect x="7" y="20" width="15" height="2" transform="rotate(-90 7 20)" fill="#03053D"/>\n' +
                '</svg>\n'
        };
    }

}


