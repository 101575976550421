import React from 'react';
import client from "../api/client";
import styled from "styled-components";

export default function ThumbnailModal({
    setThumbnailPath,
    setMobileThumbnailPath,
}) {
    const handleChangeFile = (e) => {
        const file = e.target.files[0];
        const formdata = new FormData();
        formdata.append('file', file);
        client.post('/v1/files', formdata)
            .then(res => {
                const {data} = res;
                const {data: {
                    url
                }} = data;
                setThumbnailPath(url);
            })
            .catch(err => {
                console.log(err)
                alert('이미지 업로드 에러!')
            })
    }

    const handleChangeMobileFile = (e) => {
        const file = e.target.files[0];
        const formdata = new FormData();
        formdata.append('file', file);
        client.post('/v1/files', formdata)
            .then(res => {
                const {data} = res;
                const {data: {
                    url
                }} = data;
                setMobileThumbnailPath(url);
            })
            .catch(err => {
                console.log(err)
                alert('이미지 업로드 에러!')
            })
    }
    return (
        <Container>
            <label htmlFor="pcThumb">
                <input type="file" hidden id={"pcThumb"} onChange={handleChangeFile} accept={"image/*"}/>
                PC 커버
            </label>
            <label htmlFor="mobileThumb">
                <input type="file" hidden id={"mobileThumb"} onChange={handleChangeMobileFile} accept={"image/*"}/>
                MOBILE 커버
            </label>
        </Container>
    );
}

const Container = styled.div`
  display: flex;
  align-content: center;
  
  label {
    flex: 1;
    background:#cdcdcd;
    text-align: center;
    line-height: 45px;
    color:#ffff;
    font-size: 14px;
    &:first-child {
      border-right: 1px solid #eee;
    }
  }
`;
