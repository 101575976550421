import React from 'react';
import styled from 'styled-components';
import DatePicker from "react-datepicker";
import Logo from '../assets/images/logo.png';

export default function Header({ onSubmit, isRitual, reservationType, setReservationType, reservationDate, setReservationDate, tab, setTab }) {

    return (
        <Container>
            <img src={Logo} alt=""/>
            {!isRitual && (
                <div className={'flex'}>
                    <button className={`btn ${tab === 'editor' ? 'active' : ''}`} onClick={() => setTab('editor')}>아티클 작성하기</button>
                    <button className={`btn ${tab === 'tts' ? 'active' : ''}`} onClick={() => setTab(`tts`)}>TTS 작성하기</button>
                </div>
            )}
            <div className="btnbox">
                {!isRitual && (
                    <div className={'radiobox'}>
                        <label htmlFor="temporary">
                            <input type="radio"
                                   id={"temporary"}
                                   name={'reservationType'}
                                   value="temporary"
                                   checked={reservationType === "temporary" ? true : false}
                                   onChange={(e) => setReservationType(e.target.value)} /> 임시저장
                        </label>
                        <label htmlFor="immediately">
                            <input type="radio" id={"immediately"} name={'reservationType'}
                                   value="immediately"
                                   checked={reservationType === "immediately" ? true : false}
                                   onChange={(e) => setReservationType(e.target.value)} /> 바로게시
                        </label>
                        <label htmlFor="reservation">
                            <input type="radio" id={"reservation"} name={'reservationType'}
                                   value="reservation"
                                   checked={reservationType === "reservation" ? true : false}
                                   onChange={(e) => setReservationType(e.target.value)} /> 예약게시
                        </label>
                        <div className={'datepicker'}>
                            <DatePicker
                                showTimeSelect
                                selected={reservationDate}
                                closeOnScroll={true}
                                onChange={(date) => setReservationDate(date)}
                                dateFormat={'yyyy-MM-dd HH:mm'}
                                disabled={reservationType !== 'reservation'}
                            />
                        </div>
                    </div>
                )}
                <button className={'btn'} onClick={onSubmit}>저장</button>
            </div>
        </Container>
    );
}

const Container = styled.header`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    padding:0 20px;
    height: 60px;
    align-items: center;
    background:#000;
    z-index: 7000;
    box-sizing: border-box;
    img {
        height: 20px;
    }
      .flex {
        display: flex;
      }
    @media screen and (max-width: 768px) {
        height: auto;
        top: auto;
        bottom: 0;
      display: block;
        img {
            display: none;
        }
        padding:0 10px;
      .flex {
        .btn:first-child {
          margin-right: 10px;
        }
      }
    }

    .btnbox {
        margin-left: auto;
        display: flex;
        align-items: center;
        @media screen and (max-width: 768px) {
            display: block;
            padding: 10px 0;
            margin: 0;
            width: 100%;
        }

        .radiobox {
            display: flex;
            align-items: center;
            .datepicker {
                margin-left: 10px;
                input {
                    height: 30px;
                    width: 110px;
                    font-size: 12px;
                }
            }
            @media screen and (max-width: 768px) {
                justify-content: flex-end;
            }
        }



        label {
            color: #fff;
            margin-left: 15px;
            @media screen and (max-width: 768px) {
                font-size: 13px;
                margin-left: 0;
                margin-right: 5px;
            }
        }

    }

  .btn {
    margin-left: 10px;
    cursor: pointer;
    display: block;
    height: 30px;
    background:#fff;
    border-radius: 4px;
    border: none;
    white-space: nowrap;
    padding:0 10px;
    @media screen and (max-width: 768px) {
      width: 100%;
      margin: 0;
      margin-top: 10px;
    }
    
    &.active {
      background:#009A9E;
      color:#fff;
    }
  }
`;

