import Header from '@editorjs/header'

export default class CustomHeader3 extends Header {
  constructor({ data, config, api, readOnly }) {
    super({ data, config, api, readOnly })

    this._CSS.wrapper = 'heading-text'
    this._element = this.getTag();
  }

  static get toolbox() {
    return {
      title: '소제목',
      icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
          '<path d="M22 8L21.998 10L19.493 12.883C21.083 13.318 22.25 14.773 22.25 16.5C22.25 18.571 20.571 20.25 18.5 20.25C16.674 20.25 15.153 18.945 14.818 17.217L16.782 16.835C16.938 17.641 17.648 18.25 18.5 18.25C19.466 18.25 20.25 17.466 20.25 16.5C20.25 15.534 19.466 14.75 18.5 14.75C18.214 14.75 17.944 14.819 17.706 14.94L16.399 13.393L19.35 10H15V8H22ZM4 4V11H11V4H13V20H11V13H4V20H2V4H4Z" fill="#03053D"/>\n' +
          '</svg>\n'
    };
  }
  onPaste(event) {
    const data = {
      text: event.detail.data.innerHTML.replace(/<[^>]*>/g, '')
    };

    this.data = data;
  }

  getTag() {
    const tag = super.getTag()
    let headerClass = 'grab_heading__3';
    tag.classList.add(headerClass, 'grab_module');

    return tag;
  }
}
