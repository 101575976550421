// eslint-disable-next-divider require-jsdoc
import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
import Uploader from './uploader';
import buttonIcon from './svg/button-icon.svg';
require('./index.scss').toString();

// eslint-disable-next-divider require-jsdoc
export default class SimpleCarousel {
  /**
   * @param {CarousellData} data - previously saved data
   * @param {CarouselConfig} config - user config for Tool
   * @param {object} api - Editor.js API
   */
  constructor({ data, config, api }) {
    this.api = api;
    this.data = data.data || [];
    this.IconClose =
      '<svg class="icon icon--cross" width="12px" height="12px"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#cross"></use></svg>';
    this.swiper = null;
    this.caption = data.caption;
    this.config = {
      endpoints: config.endpoints || '',
      additionalRequestData: config.additionalRequestData || {},
      additionalRequestHeaders: config.additionalRequestHeaders || {},
      field: config.field || 'image',
      types: config.types || 'image/*',
      captionPlaceholder: this.api.i18n.t('Caption'),
      urlLinkPlaceholder: this.api.i18n.t('Link'),
      buttonContent: config.buttonContent || '',
      uploader: config.uploader || undefined,
    };
    /**
     * Module for file uploading
     */
    this.uploader = new Uploader({
      config: this.config,
      onUpload: response => this.onUpload(response),
      onError: error => this.uploadingFailed(error),
    });

    this.settings = [
      {
        name: 'plus',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"><path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"/></svg>`,
      },
    ];
  }

  /**
   * CSS classes
   * @constructor
   */
  get CSS() {
    return {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      input: this.api.styles.input,
      button: this.api.styles.button,

      /**
       * Tool's classes
       */
      wrapper: 'cdxcarousel-wrapper',
      addButton: 'cdxcarousel-addImage',
      block: 'cdxcarousel-block',
      item: 'cdxcarousel-item',
      removeBtn: 'cdxcarousel-removeBtn',
      inputUrl: 'cdxcarousel-inputUrl',
      caption: 'cdxcarousel-caption',
      list: 'cdxcarousel-list',
      imagePreloader: 'image-tool__image-preloader',

      /*
       * swiper
       * */
      swiperContainer: 'swiper-container',
      swiperWrapper: 'swiper-wrapper',
      swiperSlide: 'swiper-slide',
      swiperPagination: 'swiper-pagination',
    };
  }

  /**
   * Get Tool toolbox settings
   * icon - Tool icon's SVG
   * title - title to show in toolbox
   *
   * @return {{icon: string, title: string}}
   */
  static get toolbox() {
    return {
      title: 'Carousel',
      icon: '<svg width="38" height="18" viewBox="0 0 38 18" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="10" y="0" width="18" height="18"><path fill-rule="evenodd" clip-rule="evenodd" d="M28 16V2C28 0.9 27.1 0 26 0H12C10.9 0 10 0.9 10 2V16C10 17.1 10.9 18 12 18H26C27.1 18 28 17.1 28 16V16ZM15.5 10.5L18 13.51L21.5 9L26 15H12L15.5 10.5V10.5Z"  /></mask><g mask="url(#mask0)"><rect x="10" width="18" height="18"  /></g><mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="3" width="7" height="12"><path fill-rule="evenodd" clip-rule="evenodd" d="M7 13.59L2.67341 9L7 4.41L5.66802 3L0 9L5.66802 15L7 13.59Z" fill="white"/></mask><g mask="url(#mask1)"><rect y="3" width="7.55735" height="12"  /></g><mask id="mask2" mask-type="alpha" maskUnits="userSpaceOnUse" x="31" y="3" width="7" height="12"><path fill-rule="evenodd" clip-rule="evenodd" d="M31 13.59L35.3266 9L31 4.41L32.332 3L38 9L32.332 15L31 13.59Z" fill="white"/></mask><g mask="url(#mask2)"><rect x="30.4426" y="2.25" width="7.55735" height="13" /></g></svg>',
    };
  }

  /**
   * Renders Block content
   * @public
   *
   * @return {HTMLDivElement}
   */
  render() {
    this.wrapper = make('div', [this.CSS.wrapper, this.CSS.swiperContainer]);
    this.list = make('div', [this.CSS.list, this.CSS.swiperWrapper]);

    // const caption = make('input', ['cdx-input', 'image-tool__caption']);
    // caption.setAttribute('placeholder', 'caption');

    const pagination = make('div', [this.CSS.swiperPagination]);
    const navPrev = make('div', ['swiper-button-prev']);
    const navNext = make('div', ['swiper-button-next']);

    this.addButton = this.createAddButton();

    this.list.appendChild(this.addButton);

    this.wrapper.appendChild(this.list);
    this.wrapper.appendChild(pagination);
    this.wrapper.appendChild(navNext);
    this.wrapper.appendChild(navPrev);

    // this.wrapper.appendChild(caption);

    // if(this.caption) {
    // caption.value = this.caption;
    // }
    if (this.data?.length > 0) {
      for (const load of this.data) {
        const loadItem = this.creteNewItem(load.url, load.caption);

        this.list.insertBefore(loadItem, this.addButton);
      }
    }

    setTimeout(() => {
      this.setSwiper();
    }, 0);
    return this.wrapper;
  }

  // eslint-disable-next-divider require-jsdoc
  save(blockContent) {
    const list = blockContent.getElementsByClassName(this.CSS.item);
    const data = [];

    if (list.length > 0) {
      for (const item of list) {
        console.log(item.lastChild.value);
        if (item.firstChild.value) {
          data.push({
            url: item.firstChild.value,
            caption: item.lastChild.value,
          });
        }
      }
    }
    // console.log({
    //   data,
    //   caption: blockContent.querySelector('.image-tool__caption')?.value || ''
    // });

    console.log(data);
    return {
      data,
      // caption: blockContent.querySelector('.image-tool__caption')?.value || ''
    };
  }

  /**
   * Create Image block
   * @public
   *
   * @param {string} url - url of saved or upload image
   * @param {string} caption - caption of image
   *
   * Structure
   * <item>
   *  <url/>
   *  <removeButton/>
   *  <img/>
   *  <caption>
   * </item>
   *
   * @return {HTMLDivElement}
   */
  creteNewItem(url, caption) {
    // Create item, remove button and field for image url
    const block = make('div', [this.CSS.block, this.CSS.swiperSlide]);
    const item = make('div', [this.CSS.item]);
    const removeBtn = make('div', [this.CSS.removeBtn]);
    const imageUrl = make('input', [this.CSS.inputUrl]);
    const imagePreloader = make('div', [this.CSS.imagePreloader]);
    // const captionForm = make('input', ['cdx-input', 'image-tool__caption']);
    // captionForm.setAttribute('placeholder', 'caption');

    imageUrl.value = url;
    removeBtn.innerHTML = this.IconClose;
    removeBtn.addEventListener('click', () => {
      block.remove();
      this.setSwiper();
    });
    removeBtn.style.display = 'none';

    item.appendChild(imageUrl);
    item.appendChild(removeBtn);
    block.appendChild(item);
    // block.appendChild(captionForm)
    /*
     * If data already yet
     * We create Image view
     */
    if (url) {
      this._createImage(url, item, caption, removeBtn);
    } else {
      item.appendChild(imagePreloader);
    }
    return block;
  }

  /**
   * Create Image View
   * @public
   *
   * @param {string} url - url of saved or upload image
   * @param {HTMLDivElement} item - block of created image
   * @param {string} captionText - caption of image
   * @param {HTMLDivElement} removeBtn - button for remove image block
   *
   * @return {HTMLDivElement}
   */
  _createImage(url, item, captionText, removeBtn) {
    const image = document.createElement('img');
    const caption = make('input', ['cdx-input', 'image-tool__caption']);

    image.src = url;
    if (captionText) {
      caption.value = captionText;
    }
    caption.placeholder = this.config.captionPlaceholder;

    removeBtn.style.display = 'flex';

    item.appendChild(image);
    item.appendChild(caption);
  }

  /**
   * File uploading callback
   * @private
   *
   * @param {Response} response
   */
  onUpload(response) {
    if (response.success && response.file) {
      // Берем последний созданный элемент и ставим изображение с сервера
      this._createImage(
        response.file.url,
        this.list.childNodes[this.list.childNodes.length - 2].firstChild,
        '',
        this.list.childNodes[this.list.childNodes.length - 2].firstChild
          .childNodes[1],
      );
      this.list.childNodes[
        this.list.childNodes.length - 2
      ].firstChild.childNodes[2].style.backgroundImage = '';
      this.list.childNodes[
        this.list.childNodes.length - 2
      ].firstChild.firstChild.value = response.file.url;
      this.list.childNodes[
        this.list.childNodes.length - 2
      ].firstChild.classList.add('cdxcarousel-item--empty');
    } else {
      this.uploadingFailed('incorrect response: ' + JSON.stringify(response));
    }
  }

  /**
   * Handle uploader errors
   * @private
   *
   * @param {string} errorText
   */
  uploadingFailed(errorText) {
    console.log('Gallery : uploading failed because of', errorText);

    this.api.notifier.show({
      message: this.api.i18n.t('Can not upload an image, try another'),
      style: 'error',
    });
  }

  /**
   * Shows uploading preloader
   * @param {string} src - preview source
   */
  showPreloader(src) {
    this.nodes.imagePreloader.style.backgroundImage = `url(${src})`;
  }

  // eslint-disable-next-divider require-jsdoc
  onSelectFile() {
    // Создаем элемент
    this.uploader.uploadSelectedFile({
      onPreview: src => {
        const newItem = this.creteNewItem('', '');
        newItem.firstChild.lastChild.style.backgroundImage = `url(${src})`;

        const setItem = () => {
          return new Promise((resolve, reject) => {
            this.list.insertBefore(newItem, this.addButton);
            resolve(true);
          });
        };
        setItem().then(res => {
          this.setSwiper();
        });
      },
    });
  }

  setSwiper() {
    if (this.swiper) {
      if (Array.isArray(this.swiper)) {
        // 2개 이상일 경우
        this.swiper.forEach(swiper => {
          swiper.destroy();
        });
      } else {
        this.swiper.destroy();
      }
    }

    Swiper.use([Navigation, Pagination]);
    this.swiper = new Swiper(`.${this.CSS.swiperContainer}`, {
      autoHeight: false,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: `.${this.CSS.swiperPagination}`,
        clickable: true,
      },
    });

    console.log('this.swiper', this.swiper);
  }

  renderSettings() {
    const wrapper = document.createElement('div');

    this.settings.forEach(tune => {
      let button = document.createElement('div');

      button.classList.add('cdx-settings-button');
      button.innerHTML = tune.icon;
      wrapper.appendChild(button);

      button.addEventListener('click', () => {
        this.onSelectFile();
      });
    });

    return wrapper;
  }

  /**
   * Create add button
   * @private
   */
  createAddButton() {
    const addButton = make('div', [this.CSS.button, this.CSS.addButton]);
    const block = make('div', [this.CSS.block, this.CSS.swiperSlide]);

    addButton.innerHTML = `이미지를 추가해주세요.`;
    addButton.addEventListener('click', () => {
      this.onSelectFile();
    });
    block.appendChild(addButton);

    return block;
  }
}

/**
 * Helper for making Elements with attributes
 *
 * @param  {string} tagName           - new Element tag name
 * @param  {array|string} classNames  - list or name of CSS class
 * @param  {Object} attributes        - any attributes
 * @return {Element}
 */
export const make = function make(tagName, classNames = null, attributes = {}) {
  const el = document.createElement(tagName);

  if (Array.isArray(classNames)) {
    el.classList.add(...classNames);
  } else if (classNames) {
    el.classList.add(classNames);
  }

  for (const attrName in attributes) {
    el[attrName] = attributes[attrName];
  }

  return el;
};
